.add-product {
  &__info-public {
    &__wrapper-form {
      margin: 16px;

      .ant-row {
        padding: 0 10px;

        .ant-col {
        }

        .ant-form-item-label {
          font-weight: bold;
          text-align: start !important;

          label {
            color: gray;
          }
        }
      }

      .wrapper-cate-child {
        .ant-form-item-label {
          opacity: 0;
        }
      }

      .wrapper-description {
        .ant-col-4 {
          margin-right: 16px;
          padding-right: 8px;
        }
      }
    }
  }

  &__info-sale {
    .ant-input-number-handler-wrap {
      visibility: hidden !important;
    }
    &__child {
      margin-top: 16px;

      label {
        color: gray !important;
        font-weight: bold !important;
      }

      .item {
        margin-top: 16px;
        width: 425px;
        display: flex;
        justify-content: space-between;


        .label {
          font-weight: bold;
          color: gray;
        }

        .btn {
          color: #1890ff;
          border: 1px dashed #1890ff;
        }

        .action {
          width: 200px;
        }
      }

      .type-group {
        background-color: rgb(240, 240, 240);
        padding: 16px;
        border-radius: 4px;
        margin-bottom: 8px;
        max-width: 700px;

        .group-item {
          position: relative;

          .ant-form-item-label {
            label:after {
              display: none !important;
            }
          }

          .classify-name {
            .ant-col-6 {
              opacity: 0;
            }
          }

          i {
            cursor: pointer;
          }

          .title {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 16px;
          }

          .btn-add-type-pro {
            width: 100%;
            color: #1890ff;
            border: 1px dashed #1890ff;
          }

          .ant-form-item {
            margin-bottom: 10px !important;
          }

          .close {
            position: absolute;
            top: -5px;
            right: 0;
            cursor: pointer;
            font-size: 18px;
          }
        }
      }

      .wrapper-table {
        .title {
          font-weight: bold;
        }

        .wrapper-list-option {
          display: flex;
          justify-content: space-between;
          margin: 16px 0;

          .option-left {
            display: flex;
            justify-content: center;

            .label, .price, .quantity {
              width: 200px;
            }

            .price {
              margin: 0 16px;
            }

            .quantity {
              width: 120px !important;
            }
          }

          .apply {
            background-color: #359eff;
            color: white;
          }
        }
      }
    }
  }

  &__info-img {
    &__type-pro {
      margin-top: 8px;
    }
  }

  &__wrapper-list-btn {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
    outline: none;

    &__cancel {
      width: 150px;
      margin-right: 16px;
    }

    &__save {
      width: 150px;
      background-color: #1890ff !important;
      color: white !important;
    }
  }
}