.live-stream-page {
  &__list-live {
    color: gray;
    padding: 16px;

    .total {
      font-weight: bold;
    }

    &__stream {
      .wrapper-list {

        .item {
          display: flex;
          transition: all 0.5s ease;
          cursor: pointer;
          padding: 8px;
          border-radius: 6.5px;

          .content {
            margin-left: 8px;

            .title {
              font-weight: bold;
              font-size: 15px;
            }
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
          }

        }
      }
    }

  }
}