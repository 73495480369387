$gray: #f7f7ff;
$gray-dark: #e6ebf5;
$violet-light: rgba(114, 105, 239, 0.25);
$violet-dark: rgb(114, 105, 239);

@mixin avatar {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $violet-light;
  border-radius: 50%;
  color: $violet-dark;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 16px;
}

@mixin header-footer {
  height: 90px;
  width: 100%;
  border-bottom: 1px solid $gray-dark;
  padding: 24px;
  display: flex;
  align-items: center;
  position: absolute;
}

@mixin active {
  background-color: $gray-dark;
}

@mixin input {
  background-color: $gray-dark;
  padding: 8px 16px;
  margin-top: 10px;
  border: none;
  border-radius: 6.5px !important;
}

@mixin msg {
  display: inline-block;
  border-radius: 16px;
  padding: 12px 20px;
  position: relative;
  max-width: 70%;
}

@mixin time {
  font-size: 11px;
  color: #d7d5d5;
}

@mixin name {
  color: gray;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 5px;
}

@mixin overflow-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.loading {
  height: calc(100vh - 150px);
}

.chat {
  color: gray;
  display: flex;

  input {
    @include input;
  }

  &__list {
    width: 380px;
    background-color: $gray;
    border-radius: 8px;

    &__header {
      padding: 24px 24px 0 24px;

      &__title {
        font-weight: bold;
        font-size: 21px;
      }

      &__search {
      }
    }

    &__room {
      padding: 8px;
      cursor: pointer;
      height: calc(100vh - 320px);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-dark;
        border-radius: 10px;
      }

      .active {
        @include active;
      }

      &--item {
        padding: 15px 20px;
        display: flex;
        border-radius: 6.5px;
        transition: all 0.25s ease;
        border-bottom: 1px solid $gray-dark;

        &:hover {
          @include active;
        }
      }

      &--avatar {
        @include avatar;
        margin-right: 8px;
      }

      &--content {
        flex-grow: 1;
      }

      &--wrapper-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--name {
        font-weight: bold;
        width: 200px;
        @include overflow-text;
      }

      &--time {
        font-size: 12px;
      }

      &--msg {
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .msg {
          width: 230px;
          @include overflow-text;
        }

      }

    }
  }

  &__windows {
    background-color: white;
    flex-grow: 1;
    height: calc(100vh - 140px);
    border-radius: 8px;
    position: relative;


    &__header {
      @include header-footer;
      top: 0;

      &--avatar {
        @include avatar;
        margin-right: 8px;
      }

      &--name {
        font-weight: bold;
      }
    }

    &__win-msg {
      padding: 24px 24px;
      height: calc(100vh - 320px);
      margin-top: 90px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-dark;
        border-radius: 10px;
      }

      &--wrapper-msg-left {
        margin-top: 20px;
        color: white;
        display: flex;


        .time {
          @include time;
          order: 3;
          margin-left: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .time-img {
          align-items: flex-end;
          justify-content: center;
        }

        .msg {
          @include msg;
          background-color: $violet-dark;
          order: 1 !important;
        }

        .wrapper-avatar {
          .ant-avatar {
            order: 0 !important;
            margin-right: 6px;
          }

          .name {
            @include name;
          }
        }
      }

      &--wrapper-msg-right {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        .time {
          @include time;
          order: 0;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .time-img {
          align-items: flex-end;
          justify-content: center;
        }

        .msg {
          @include msg;
          background-color: $gray-dark;
          margin-right: 4px;
          order: 1;
        }

        .wrapper-avatar {
          order: 2;

          .avatar {
            @include avatar;
            order: 2;
            margin-left: 8px;
          }
        }
      }

    }

    &__send-msg {
      @include header-footer;
      bottom: 0;
      border-top: 1px solid $gray-dark;

      .fa-paper-plane {
        position: absolute;
        top: calc(50% - 5px);
        right: 40px;
        color: $violet-dark;
        font-size: 18px;
        cursor: pointer;
      }

      .ant-upload-list, .ant-upload-list-text {
        position: absolute;
        top: 0;
        right: 25px !important;
      }

      .file-name {
        position: absolute;
        top: 0;
        left: 28px;
        font-size: 12px;
        background-color: $violet-dark;
        color: white;
        padding: 4px 15px;
        z-index: 1;
        border-radius: 4px;

        i {
          cursor: pointer;
        }
      }

      .wrapper-upload {
        position: relative;

        .fa-paperclip {
          @extend .fa-paper-plane;
          transform: translateX(110%);
        }

        .upload {
          height: 20px;
          width: 20px;
          position: absolute;
          top: calc(50% - 15px);
          right: 60px;
          z-index: 1;
          background-color: transparent;
          opacity: 0;
          cursor: pointer;
          transform: translateX(50%);
        }

      }
    }
  }
}