.report-sale {
  &__header {
  }

  &__wrapper-table {
    &__filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .list-filter {
        .item {
          width: 300px;
          margin-right: 8px;
        }
      }

      .count-filter {
        font-weight: bold;
        color: gray;
      }
    }

  }
}