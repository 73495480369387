@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("OpenSans-SemiBold");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

body {
  background-color: #f2f6f7;
  font-family: "OpenSans-Semibold", sans-serif !important;
}

$green: rgb(16, 151, 3);
$blue: #1890ff;
$red: #ff253a;

//css common
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.display-flex {
  display: flex;
}

.mr-16 {
  margin-right: 16px;
}

.mr-24 {
  margin-right: 24px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.color-gray {
  color: rgb(122, 135, 142);
}

.font-bold {
  font-weight: bold;
}

.style-box {
  padding: 16px;
  background-color: white;
  border-radius: 8px;
}

.box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrapper-collapse {
  .ant-collapse-header {
    font-weight: bold;
    font-size: 16px;
    background-color: rgb(10, 177, 252);
    border-radius: 4px !important;
    color: white !important;
  }
}

.color-black {
  color: black;
}

.ant-modal-title {
  font-weight: bold !important;
}

button {
  border-radius: 4px !important;
}

.ant-table-thead tr th {
  font-weight: bold !important;
  color: gray !important;
}

.ant-table-content {
  color: gray;
}

input, .ant-picker, .ant-select-selector {
  border-radius: 4px !important;
}

.btn--red {
  background-color: $red !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px !important;
  border: 1px solid $red;
  outline: none;
}

.btn--green {
  @extend .btn--red;
  background-color: $green !important;
  border: 1px solid $green;
}

.btn--orange {
  @extend .btn--red;
  background-color: orange !important;
  border: 1px solid orange;
}

.ant-tag {
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
