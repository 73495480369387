.add-notification-page {
  &__form {
    .item {
      display: flex;
      margin-bottom: 16px;

      .label {
        color: gray;
        font-weight: bold;
        width: 120px;
      }

      .input {
        flex-grow: 1;
      }
    }
  }
}