.header_container {
    background-color: white;
    border-radius: 8px;
}

.header_container p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.content_filter_container {
    background-color: white;
    margin-top: 10px;
    padding: 15px;
    border-radius: 8px;
}

.content_container {
    background-color: white;
    margin-top: 10px;
    padding: 15px;
    padding-bottom: 20px;
    border-radius: 8px;
    position: "relative";
    overflow: auto;
}

.content_container_with_height {
    background-color: white;
    margin-top: 10px;
    padding: 15px;
    padding-bottom: 20px;
    border-radius: 8px;
    position: "relative";
    min-height: 400px;
}
