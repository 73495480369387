.detail-livestream {
  &__info {
    .title {
      font-weight: bold;
      margin-bottom: 16px;
      font-size: 15px;
    }

    .item {
      color: gray;
      margin-bottom: 6px;

      .label {
        font-weight: bold;
        display: inline-block;
        width: 200px;
      }
    }

  }

  &__comment {
    .title-comment {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .list-comment {
      height: 600px;
      overflow-y: scroll;
      border-right: 1px solid #e3d6d6;

      &:last-child {
        border-right: none;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }

      .comment {
        display: flex;
        align-items: center;

        .text {
          margin-left: 8px;
          color: gray;
          flex-grow: 1;
        }
      }

      .product-live {
        border-bottom: 1px solid #e6ebf5;
        border-radius: 5.5px;
        padding: 15px 5px;
        margin-bottom: 8px;
        transition: all 0.2s ease-in-out;

        &:hover {
          box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
      }
    }

  }
}