.product-page {
  &__header {
    .btn {
      border: none;
      outline: none;
    }

    .btn-update {
      margin: 0 16px;
    }

    .btn-add {
      background-color: green;
    }
  }

  &__filter {
    background-color: white;
    margin-top: 16px;
    padding: 16px;
    border-radius: 4px;

    .search {
      width: 200px;
    }

    .cate {
      margin: 0 16px;
      width: 200px;
    }

    .status {
      width: 200px;
    }
  }

  &__table {
    padding: 16px;
    background-color: white;
    border-radius: 4px;
    margin-top: 16px;

    .pagination {
      display: flex;
      justify-content: flex-end;
      margin: 24px 16px 0 16px;
    }
  }
}