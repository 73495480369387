.product-category {
  &__filter {
    background-color: white;
    border-radius: 4px;
    margin-top: 16px;
    padding: 16px;

    .product-category__filter-select{
      margin-right: 16px;
    }
  }

  &__btn-add{
    margin-left: 15px;
    background-color: green !important;
    color: white;
  }

  &__wrapper-table-cate{
    background-color: white;
    border-radius: 4px;
    padding: 0 16px;

    &__expand-table{
      padding: 16px;
      background-color: rgb(240, 242, 245);
      border-radius: 4px;

      &__tabs-info-cate{
        padding: 15px 40px;
        background-color: white;
        border-radius: 6px;

        &__info{
          border-bottom: 1px solid rgb(240, 242, 245);
          padding-bottom: 15px;
        }

        &__wrapper-list-btn {
          .ant-col {
            border-left: 1px solid rgb(224, 224, 224);

            &:first-child {
              border: none;
            }
          }
        }
      }
    }
  }

  .ant-table {
    margin: 0 !important;
  }

  &__wrapper-pagination{
    display: flex;
    justify-content: flex-end;
    background-color: white;
    padding: 16px;
  }
}