.order-pro {

  &__list-order {
    &__filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__list-filter {
        .item {
          margin-right: 16px;
          margin-top: 4px;
          width: 300px;
        }
      }
    }
  }
}