.customer {
  &__wrapper-table {
    color: gray;
    &__filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__total {
        color: gray;
        span{
          font-weight: bold;
          color: orangered;
        }
      }
    }

    &__table{
      .wrapper-list-info-user{
        border-right: 1px solid gray;

        .wrapper-info-user{
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          color: gray;

          i{
            margin-right: 8px;
          }
        }
      }

      .dashboard-customer{

        .dashboard-customer-row{
          margin-top: 4px;
          .dashboard-customer-item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .dashboard-customer-help{
              color: gray;
              font-size: 13px;
              margin-top: 4px;
            }

            .dashboard-customer-value{
              font-weight: bold;
              font-size: 18px;
            }
          }
        }
      }
    }

  }
}