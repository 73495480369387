.order-detail {
    &__info {
        &__customer {
            min-height: 210px;

            .info {
                .info-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 6px;
                    color: gray;

                    i {
                        margin-right: 8px;
                    }
                }
            }
        }

        &__order-pro {
            min-height: 175px;

            .info {
                .note-item {
                    align-items: flex-start !important;
                }

                .info-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 6px;

                    .title-info-item {
                        width: 200px;
                        color: gray;
                    }

                    .count {
                        color: #1890ff;
                        font-weight: bold;
                        margin-right: 4px;
                    }

                    .total {
                        color: #ff253a;
                    }

                    .note {
                        height: 50px;
                        overflow-y: scroll;

                        &::-webkit-scrollbar {
                            width: 10px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #d9d9d9;
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-track {
                            background: white;
                        }
                    }
                }
            }
        }
    }

    &__list-pro-order {
        .title {
            font-weight: bold;
            margin-bottom: 16px;
        }

        .item {
            height: 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            color: gray;
            border-radius: 4px;
            transition: all 0.1s ease-in-out;
            cursor: pointer;
            font-size: 13px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }

            .value {
                font-weight: bold;
                font-size: 13px;
            }
        }
    }
}
