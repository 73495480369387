#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-popover-placement-bottomLeft, .ant-popover-placement-bottom {
  left: 0 !important;
}


//STYLE HEADER MENU
.ant-layout-sider {
  background-color: white !important;
}

.ant-popover-inner-content {
  padding: 2px !important;
}

.home {
  &__logo-name {
    color: orangered;
    margin-right: 45px;
    font-weight: bold;
    font-size: 18px !important;
  }

  &__logo {
    padding: 20px 10px;

    &-avatar {
      margin-bottom: 10px;

      img {
        height: 64px;
        width: 64px;
        border-radius: 50%;
      }
    }

    &-info {
      .name,
      .gmail {
        text-align: center;
      }

      .name {
        font-size: 13px !important;
        font-weight: bold;
      }

      .gmail {
        font-size: 12px;
        color: #4d627b;
      }
    }
  }

  &__manage-user {
    margin: 4px;

    &__item {
      font-size: 12px;
      padding: 4px;
      font-weight: bold;

      &:hover {
        cursor: pointer;
        background-color: rgb(240, 240, 245);
      }
    }
  }

  &__notification {
    position: fixed;
    top: 70px;
    right: 140px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 4.5px;
  }

  &__msg-notification {
    position: fixed;
    top: 70px;
    right: 90px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 4.5px;
  }

  &__user-notification {
    position: fixed;
    top: 70px;
    right: 0;
    width: 140px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 4.5px;
  }

  &__wrapper-notification {
    max-height: 500px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 10px;

      &:hover {
        display: block;
      }
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      display: none;
    }
  }
}

//END
