.product-detail {
  &__header {
    &__list-btn {
      .btn {
        color: white;
        border: none;
        outline: none;
        padding: 6px 20px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }

      .btn-delete {
        background-color: grey;
      }

      .btn-edit {
        background-color: orange;
        margin: 0 16px;
      }

      .btn-status {
        background-color: #ff253a;
      }
    }
  }

  &__info {
    &__tab {
      display: flex;
      margin: 16px 0;
      font-size: 15px;
      font-weight: bold;

      .active {
        background-color: #359eff;
        color: white !important;
        border: 1px solid #359eff !important;
      }

      .tab {
        padding: 6px 0px;
        cursor: pointer;
        border-radius: 4px;
        width: 190px;
        display: flex;
        justify-content: center;
        color: grey;
        border: 1px solid gray;
      }

      .tab-info-pro {
        margin-right: 24px;
      }
    }

    &__tab-info-pro {
      .info-public {
        border-radius: 4px;

        .title-info {
          font-size: 15px;
        }

        .title {
          font-weight: bold;
          color: grey;
        }

        .description {
          height: 20px;
          width: 300px;
          overflow: hidden;
        }
        .load-more{
          padding-bottom: 8px;
        }
      }

      &__tab-info-sale {

      }
    }
  }

  &__img {
    .title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .ant-image{
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    }

    .label {
      font-weight: bold;
      color: grey;
    }

    .wrapper-img, .wrapper-video, .wrapper-type {
      display: flex;
    }

    .wrapper-video {
      margin: 18px 0;

      .label {
        margin-right: 35px;
      }
    }

    .wrapper-type {
      .label {
        margin-right: 80px;
      }
    }
  }

  &__price {
    .title {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 16px;
    }

    table tr th {
      font-weight: normal !important;
    }
  }

}