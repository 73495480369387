.dashboard {
  overflow: hidden;
  &__statistical {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 16px;

    &__item {
      width: 260px;
      margin-right: 10px;
      display: inline-block;
    }

    &__item:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .dashboard {
    &__statistical {
      width: 100%;
      display: block;

      margin-top: 16px;

      &__item {
        width: 260px;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}