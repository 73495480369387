.login {
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    right: -50px;
    background-image: url("https://images.unsplash.com/photo-1695653422902-1bea566871c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80");

    &__wrapper-form {
        height: 350px;
        width: 580px;
        background-color: white;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        padding: 0 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        .ant-row {
            width: 100%;
        }

        .wrapper-img {
            height: 100%;
            width: 100%;
        }

        &__name {
            font-weight: bold;
            color: red;
        }
    }
}

@media screen and (max-width: 700px) {
    .login {
        &__wrapper-form {
            height: 80%;
            width: 90%;
        }
    }
}
